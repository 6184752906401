import React, { useState,useEffect,useRef } from 'react';
import { useParams } from "react-router-dom";

import useTextField from '../../../../hooks/useTextField';

import { Gear,PlusSquare, Pencil,XCircle,Save,CashStack } from 'react-bootstrap-icons';
import { useLocalStorage } from '../../../../useLocalStorage';
import { createFormDataObj,apiDataFetch,dateTool } from '../../../../Utilities';
import GLDSFormModal from '../../../GLDSFormModal/GLDSFormModal';

import DropDownSelect from '../../../DropDownSelect/DropDownSelect';
import loader from '../../../../svg/tail-spin.svg';
import UpdatePasswordForm from '../../Forms/UpdatePasswordForm/UpdatePasswordForm';
import SquareCheckboxInput from '../../../SquareCheckboxInput/SquareCheckboxInput';
import Button from '../../../Button';
import MessageBanner from '../../../MessageBanner/MessageBanner';
import WEButton from '../../../../ui/WEButton/WEButton';
import './LicenseDetailView.css';
import styles from './licenseDetailView.module.css';
import UserListPanel from '../../Components/UserListPanel/UserListPanel';
import NewCustomerPanel from '../../Components/NewCustomerPanel/NewCustomerPanel';


const dropdownContent = [
    {
        action: 'edit',
        title: 'Edit',
        icon: <Pencil size={18} />
    }
];




const LicenseDetailView = () => {
    // Session and current user local storage vars
    const [session, setSession] = useLocalStorage('session');
    const [userId, setUserId] = useLocalStorage('uid');
    console.log(`SESSION: ${session}`);
    // React Router useParams custom hook
    const urlParams = useParams();

    // useRef hook vars
    const navIconRef = useRef(null);
    const nameTFRef = useRef(null);
    const firstRenderRef = useRef(true);
    const defaultFocusRef = useRef(null);
    const salePriceFieldRef = useRef(null);
    const dueDateFieldRef = useRef(null);
    const monthTermBtnRef = useRef(null);
    const yearTermBtnRef = useRef(null);

    


    const [didCollect, setDidCollect] = useState(false);
    const [salePrice, setSalePrice] = useState('0.00');
    const [termType, setTermType] = useState('month');
    const [termStart, setTermStart] = useState();
    const [termEnd, setTermEnd] = useState();
    const [payMethod, setPayMethod] = useState();
    const [paymentDate, setPaymentDate] = useState();
    const [disableBilling, setDisableBilling] = useState(false);

    // Component state vars
    const [isLoading, setIsLoading] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);    
    const [showPWModal, setShowPWModal] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(true);    
    const [viewData, setViewData] = useState({
        id: "",
        org_id: '',
        product_id: '',
        seats: {},
        num_seats: null,
        used_seats: null
    })
    const [licensedUsers, setLicensedUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [product, setProduct] = useState({});
    const [checkedStates, setCheckedStates] = useState([]);
    const [listUsers, setListUsers] = useState([]);
    const [billingTerms, setBillingTerms] = useState({});


    const [numberOfSeats, setNumberOfSeats] = useState({
        total: 0,
        used: 0
    });


    const findLicensedUsers = users => {
        let retArr = [];
        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            let res = viewData.seats.users.filter(usr => usr === user.id)
            if (res.length !== 0){
                retArr.push(user.id);    
            }
        }
        return retArr;
    }
    

    // Data fetching methods
    /**
     *  Fetch call to get license from the id
     *  passed in from the URL
     * 
     *  calls 
     *      @function setViewData()
     *  to set License data upon successful return
     *  return @object
     *      id,
     *      num_seats,
     *      org_id,
     *      product_id
     *      seats,
     *          total,
     *          used,
     *          users
     *      used_seats          
     **/
    const fetchLicenseForId = (_licenseId) => {
        let fd = createFormDataObj({
            action: 'get_license',
            session,
            uid: userId,
            id: _licenseId
        });

        apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
            console.log('Organization detail view');
            console.log(result);
            if (result.success === true){
                let usr = result.data[0];
                setViewData({...viewData,...usr});
                setNumberOfSeats({total:result.data[0].seats.total,used:result.data[0].seats.used});
            } 
        }).catch(error => {
            console.error('Error:',error);
        })
    }
    /**
     *  Fetch call to users of an organization
     *  using org_id from viewData state var
     * 
     *  calls 
     *      @function setUsers()
     *  to set License data upon successful return
     *  *  request return data @object
     *      id,
     *      name,
     *      email,
     *      isAdmin,
     *      org_id,
     *      org_name
     **/ 
    const fetchUsersForOrganization = (_orgId) => {
        let fd = createFormDataObj({
            action: 'list_users',
            session,
            uid: userId,
            org_id: _orgId
        });
        apiDataFetch(window.API_URL,{method:'POST',body:fd}).then(result => {
            console.table(`API RESPONSE: Success=${result.success}, Message=${result.message}`);                   
            if(result.success === true){
                let licensedUsers = findLicensedUsers(result.data);
                setUsers(result.data);
                setLicensedUsers(findLicensedUsers(result.data));
                setListUsers(findLicensedUsers(result.data));
                let tmpArr  = new Array(result.data.length).fill(false);                
                for (let i = 0; i < viewData.seats.users.length; i++) {
                    const usr = viewData.seats.users[i];
                    for (let k = 0; k < result.data.length; k++) {
                        const user = result.data[k];
                        if (user.id === usr) {
                            tmpArr[k] = true;
                        }
                    }
                }
                setCheckedStates([...tmpArr])
                return;
            }
        });
    }
    /**
     *  Fetch call that returns the product
     *  the license is tied to
     * 
     *  calls 
     *      @function setProduct()
     *  to set License data upon successful return
     *  *  request return data @object
     *      uid,
     *      name,
     *      srp,
     *      description
     **/ 
    const fetchProductForLicense = (_prodId) => {
        let fd = createFormDataObj({
            action: 'get_product',
            session,
            uid: userId,
            id: _prodId
        });
        
        apiDataFetch(window.API_URL,{method:'POST',body:fd}).then(result => {
            console.table(`API RESPONSE: Success=${result.success}, Message=${result.message}`);                   
            if(result.success === true){
                setProduct(result.data);
                return;
            }
        }).catch(error => {
            console.error('Error:',error);
        })
    }
    const fetchBillingTermsForId = (_licenseId) => {
        
        let fd = createFormDataObj({
            action: 'get_billing',
            session,
            uid: userId,
            id: _licenseId
        });

        apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
            console.log('Organization detail view');
            console.log(result);
            if (result.success === true){
               
                setBillingTerms(result.data);
                setTermEnd(result.data.term_end.split(" ")[0]);
                setPayMethod('Credit/Debit Card');

                if (result.data.term_type === 'year'){
                    yearTermBtnRef.current.classList.add(styles['active-button']);
                }else{
                    monthTermBtnRef.current.classList.add(styles['active-button']);
                }
                
                if (!result.data?.term_start){
                    setDisableBilling(true);
                }

                let today = dateTool.todaysDate();
                let dueDate = result.data.term_end;

                if (today < dueDate){
                    // alert('No payment due');
                    dueDateFieldRef.current.classList.remove(styles.past_due);
                }else{
                    // alert('Payment passed due');
                    dueDateFieldRef.current.classList.add(styles.past_due);
                }

                setSalePrice(`$${parseFloat(result.data.sale_price).toFixed(2)}`);
                setPaymentDate(dateTool.todaysDate(false))
            }
            setPayMethod('Credit/Debit Card');
            setDisableBilling(true);
        }).catch(error => {
            console.error('Error:',error);
        })
    }

    
    

    // get license data onload
    useEffect(() => {
        fetchLicenseForId(urlParams.id);
        fetchBillingTermsForId(urlParams.id);
    },[urlParams.id]);
    // get user data onload; on completion of license fetch
    useEffect(() => {
        if (firstRenderRef.current === true && viewData.id === "") return;
        if (firstRenderRef.current === false) return;
        fetchUsersForOrganization(viewData.org_id);
        firstRenderRef.current = false;
    },[viewData])
    // get product data onload; on completion of user fetch
    useEffect(() => {
        if (users.length === 0) return;
        if (users[0].id === "") return;
        fetchProductForLicense(viewData.product_id);
    },[users])
    
    
    // toggles the dropdown menu when clicking the gear icon
    const toggleDropdownMenu = (e) => {
        setIsDropdownVisible(isDropdownVisible ? false:true);
        navIconRef.current.classList.toggle('active');
    }
    // handles the dropdown menus selection event
    const dropdownAction = (e) => {
        switch (e.view) {
            case "edit":
                setIsReadOnly(!isReadOnly);
                defaultFocusRef.current.focus();
                setListUsers(users);
                setDisableBilling(false);
            default:
                break;
        }
    }

    const endEditEvent = (e) => {
        setIsReadOnly(true);
        setListUsers(licensedUsers);
        

        let fd = createFormDataObj({
            action: "update_license",
            session,
            uid: userId,
            update_id: viewData.id,
            product_id: product.id,
            type: product.type,
            seats: parseInt(viewData.seats.total),
            details: JSON.stringify(licensedUsers),
            billing: JSON.stringify({
                term_type: termType,
                term_start: termStart,
                term_end: termEnd,
                sale_price: salePrice,
                collected: didCollect ? 1 : 0,
                paid_on_date: paymentDate,
                method: payMethod
            })
        });
        
        apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
            console.log(result.message);
            if (result.success === true){
                setIsReadOnly(true);
                fetchLicenseForId(viewData.id);
            }    
        }).catch(error => {
            console.error('Error:',error);
        })
    }
    const cancelEditEvent = (e) => {
        setIsReadOnly(true);
        setListUsers(licensedUsers);
        setDisableBilling(true);
    }

    
   

    const checkboxClickEvent = (e,eleIndex) => {
        debugger;
        let checkedStatus = checkedStates[eleIndex];        
        let checkboxVal = e.target.dataset.value;

        // let li = e.target.closest('li');
        // let ele = li.querySelector('.license__user__item__loading');
        // let loadingEle = li.querySelector('.license__user__item__loading');
        // let bannerEle = li.querySelector('.license__user__item__banner');
        // li.classList.toggle('is-loading');
        
        
        
        let updatedCheckState = checkedStates.map((check,index) => {
            return index === eleIndex ? !check : check;
        })
        
        
        if (checkedStatus){
            let results = licensedUsers.filter(id => id !== checkboxVal);
            setLicensedUsers([...results]);
            setCheckedStates(updatedCheckState);
            numberOfSeats.used--
            let tmpUsed = numberOfSeats.used;
            setNumberOfSeats({...numberOfSeats,...{used:tmpUsed}});
            return;            
        }
                
        if (parseInt(numberOfSeats.total) !== parseInt(numberOfSeats.used)){
            setCheckedStates(updatedCheckState);
            setLicensedUsers([...licensedUsers, parseInt(checkboxVal)]);
            numberOfSeats.used++;
            let tmpUsed = numberOfSeats.used;
            setNumberOfSeats({...numberOfSeats,...{used:tmpUsed}});
        }
    }

    const totalSeatsInputChangeEvent = (e) => {
        let tmp = {...viewData,seats:{...viewData.seats,total:e.target.value}};
        setViewData(tmp);
    }




    const [name,nameFieldStatus,setName,setNameFieldStatus] = useTextField();
    const [email,emailFieldStatus,setEmail,setEmailFieldStatus] = useTextField();
    const [password,passwordFieldStatus,setPassword,setPasswordFieldStatus] = useTextField();
    const [isAdmin, setIsAdmin] = useState(false);

    const updateIsAdmin = () => {
        let tmpIsAdmin = isAdmin;
        if (tmpIsAdmin === true){
            setIsAdmin(false);
            return;
        }
        setIsAdmin(true);
    }

    const submitNewUser = (e) => {
        e.preventDefault();
        let fd = createFormDataObj({
            action: "create_user",
            session,
            uid: userId,
            
            name,
            email,
            password,
            isAdmin: isAdmin ? "Yes" : "No"
        });

        apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
            console.log('Organization detail view');
            console.log(result);
            debugger;
            if (result.success === true){         
                setUsers([...users,result.data]);
                checkedStates.push(false);
                setName({target:{value:""}});
                setEmail({target:{value:""}});
                setPassword({target:{value:""}});
            } 
        }).catch(error => {
            console.error('Error:',error);
        })
    }


    console.log('UserDetailView.js checkedStates: '+JSON.stringify(checkedStates));
    return (
        <div className={styles.license_detail_view} id="license-detail-view">

            <nav className={styles.license_detail_nav}>
                <div ref={navIconRef} className={styles.nav_icon} onClick={toggleDropdownMenu}>
                    <span><Gear size={35} /></span>
                    {isDropdownVisible &&  (
                        <DropDownSelect direction={"right"} viewAction={setIsDropdownVisible} content={dropdownContent} selectAction={dropdownAction} />
                    )}
                </div>
            </nav>
            

            <div className={styles.title}>
                <h2>Product License: <span>{product.name}</span></h2>
            </div>
            <div className={styles.subtitle}>
                <h4>{viewData.org_name}</h4>
            </div>

            <div className={styles.detail_view}>
                
                <div className={styles.detail_view_wrapper}>

                    <div className={styles.app_license_detail}>

                        {/* Licenses detail text fields | first section */}
                        <div className={`${styles.detail_col} ${styles.license_detail_col}`}>
                            <div className={styles['license_detail_heading']}>
                                License Details:
                            </div>
                            <div className={styles.form_container}>

                                <div className={'form-floating mb-3'}>
                                    <input
                                        ref={nameTFRef}
                                        type="text"
                                        className="form-control"
                                        id="license-id"
                                        value={viewData.id}
                                        placeholder="License ID"
                                        readOnly={true}
                                    />
                                    <label htmlFor="license-id" aria-label='License ID field'>License ID</label>
                                </div> 

                                <div className={'form-floating mb-3'}>
                                    <input
                                        ref={defaultFocusRef}
                                        type="text"
                                        className="form-control"
                                        id="total-seats"
                                        value={viewData.seats.total}
                                        onChange={totalSeatsInputChangeEvent}
                                        placeholder="Total seats"
                                        readOnly={isReadOnly}                                    
                                    />
                                    <label htmlFor="total-seats" aria-label='Total seats field'>Total seats</label>
                                </div>

                                <div className={'form-floating'}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="avail-seats"
                                        value={viewData.seats.used ? (viewData.seats.total - viewData.seats.used) : viewData.seats.total}
                                        placeholder="Available seats"
                                        readOnly={true}
                                    />
                                    <label htmlFor="avail-seats" aria-label='Available seats field'>Available seats</label>
                                </div>

                            </div>
                        </div>

                        <div className={`${styles.detail_col} ${styles.license_user_col}`}>
                            <UserListPanel>
                                <UserListPanel.Heading>Assign user to license</UserListPanel.Heading>
                                <UserListPanel.SubHeading>Available users</UserListPanel.SubHeading>
                                <UserListPanel.List>
                                    {
                                        users.map((user,index) => {
                                            return (
                                                <UserListPanel.ListItem 
                                                    index={index}
                                                    isChecked={checkedStates[index]}
                                                    onChange={(e) => checkboxClickEvent(e,index)}
                                                    item={user}
                                                />
                                            )
                                        })
                                    }
                                </UserListPanel.List>
                                <UserListPanel.ListDetails total={numberOfSeats.total} used={numberOfSeats.total - numberOfSeats.used} />
                            </UserListPanel>
                        </div>

                        <div className={`${styles.detail_col} ${styles.license_billing_col}`}>
                            
                            <NewCustomerPanel>
                                <NewCustomerPanel.Heading>{"Add new user:"}</NewCustomerPanel.Heading>
                                <NewCustomerPanel.FormContainer>
                                    <NewCustomerPanel.NameField 
                                        id={"user_name"}
                                        placeholder={"Name"}
                                        label={"Name"}
                                        value={name}
                                        action={setName}
                                    />
                                    <NewCustomerPanel.EmailField 
                                        id={"user_email"}
                                        placeholder={"Email"}
                                        label={"Email"}
                                        value={email}
                                        action={setEmail}
                                    />
                                    <NewCustomerPanel.PasswordField
                                        id={"user_password"}
                                        placeholder={"Password"}
                                        label={"Password"}
                                        value={password}
                                        action={setPassword}
                                    />
                                    <NewCustomerPanel.Toggle name={"is_admin"} isChecked={isAdmin} action={updateIsAdmin} label={"Is Admin:"} />
                                    <NewCustomerPanel.SubmitButton onClick={submitNewUser}>
                                        {"Add User"}
                                    </NewCustomerPanel.SubmitButton>
                                </NewCustomerPanel.FormContainer>
                            </NewCustomerPanel>

                        </div>

                    </div>

                    <div className={styles.svc_license_detail}>
                        
                    </div>

                </div>

           </div>

           {!isReadOnly && <div className='btm__nav'>
                <div className="row">
                    <div className='col-2'></div>
                    <div className="col-4">
                        <div className='add__user__btn__container'>
                            <WEButton btnColor='#DA2C38' disabled={disabledBtn} onClick={cancelEditEvent} type={'outline'}>
                                <div className='btn-content cancel'>
                                    <span><XCircle size={18} /></span>
                                    <span>Cancel</span>
                                </div>
                            </WEButton>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className='add__user__btn__container'>
                            <WEButton btnColor='#00a651' disabled={disabledBtn} onClick={endEditEvent} type={'outline'}>
                                <div className='btn-content save'>
                                    <span><Save size={18} /></span>
                                    <span>Save</span>
                                </div>
                            </WEButton>
                        </div>                            
                    </div>
                    <div className='col-2'></div>
                </div>
           </div>}

        </div>
    ); 
}
export default LicenseDetailView;

import React, { useState,useContext,useEffect,useRef } from 'react';
import { useParams,useNavigate } from "react-router-dom";

import { useLocalStorage } from '../../../../useLocalStorage';

import { createFormDataObj } from '../../../../Utilities';
import BillingDetailPanel from '../../Components/BillingDetailPanel/BillingDetailPanel';

import styles from './billingDetailsView.module.css';

function BillingDetailsView({ coords,updatePopoverCoords,license,action }){
    const [session] = useLocalStorage('session');
    const [userId] = useLocalStorage('uid');
    const [storeName,setStoreName] = useState("");
    const [productName,setProductName] = useState("");


    const [price,setPrice] = useState("35.00");
    const [term,setTerm] = useState("");
    const [termDate,setTermDate] = useState("");
    const [payType,setPayType] = useState("ACH");

    const fetchBillingTermDetails = () => {
        let fd = createFormDataObj({
            action: 'get_billing',
            session,
            uid: userId,
            id: license
        });

        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => {            
            if (result.success === true){
               let data = result.data[0];
               setPrice(data.sale_price);

            }            
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }
    const fetchLicenseDetails = () => {
        let fd = createFormDataObj({
            action: 'get_license_details',
            session,
            uid: userId,
            lid: license
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => {
            if (result.success === true){
                let data = result.data[0];
                setStoreName(data.org_name);
                setProductName(data.product_name);                
            }            
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }

    useEffect(() => {
        fetchLicenseDetails();
        fetchBillingTermDetails();
    },[license]);

    const termButtonClickAction = (e) => {
        console.log(e);
        setTerm(e);        
        console.log("Term Button Click action");
    }
    const termDateButtonClickAction = (e) => {
        console.log(e);
        setTermDate(e);
        console.log("Term Date Button Click action");
    }

    const paymentMethodChangeEvent = (e) => {
        console.log(e);
        setPayType(e.target.value);
        console.log("Payment method Change action");
    }

    const paymentAmountChangeEvent = (e) => {
        setPrice(e.target.value);
    }

    const saveBillingDetails = (e) => {
        let fd = createFormDataObj({
            action: 'create_billing_details',
            session,
            uid: userId,
            license,
            price,
            term,
            termDate,
            payType
        });

        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => {
            if (result.success === true){                
                action(e);
            }            
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }

    

    return (
        <div style={{...coords}} className={styles.bill_detail}>
            <div className={styles.bill_detail_wrapper}>
                <div className={styles.close_btn} onClick={action}>X</div>
                <div className={styles.heading}>
                    <h1>{"Billing Details"}</h1>
                </div>
                <div className={styles.sub_heading}>
                    <h3>{storeName}</h3>
                </div>
                <div className={styles.sub_heading}>
                    <h5>{productName}</h5>
                </div>
                <div className={styles.body}>
                    <BillingDetailPanel>
                        <BillingDetailPanel.FormContainer>
                            <BillingDetailPanel.ButtonGroup>
                                <BillingDetailPanel.ButtonGroup.Button name={"Month"} action={termButtonClickAction} />
                                <BillingDetailPanel.ButtonGroup.Button name={"Year"} action={termButtonClickAction} />
                            </BillingDetailPanel.ButtonGroup>
                            <BillingDetailPanel.ButtonGroup>
                                <BillingDetailPanel.ButtonGroup.Button name={"7"} action={termDateButtonClickAction} />
                                <BillingDetailPanel.ButtonGroup.Button name={"21"} action={termDateButtonClickAction} />
                            </BillingDetailPanel.ButtonGroup>
                            <BillingDetailPanel.PaymentTypeSelect id={"pay_options"} action={paymentMethodChangeEvent} label={"Select payment method"} defaultValue={'ach'}>
                                <option value={'card'}>Credit/Debit Card</option>
                                <option value={'check'}>Check</option>
                                <option value={'cash'}>Cash</option>
                                <option value={'ach'} >ACH</option>
                            </BillingDetailPanel.PaymentTypeSelect>
                            <BillingDetailPanel.FeeTextField id={"price"} placeholder={"App price"} label={"App price"} value={price} action={paymentAmountChangeEvent} />
                            <BillingDetailPanel.Button action={saveBillingDetails}>{"SAVE"}</BillingDetailPanel.Button>
                        </BillingDetailPanel.FormContainer>
                    </BillingDetailPanel>
                </div>
            </div>
        </div>
    );
}

export default BillingDetailsView;
import React, { useState } from 'react';

const useTextField = () => {
    const [value,setValue] = useState("");
    const [status,setStatus] = useState("idle");

    const onChangeEvent = (e) => {
        setValue(e.target.value);
    }

    const onStatusChangeEvent = (_status="idle") => {
        setStatus(_status);
    }

    return [value,status,onChangeEvent,onStatusChangeEvent]
}

export default useTextField;
import React from 'react';
import WECheckbox from '../../../WECheckbox/WECheckbox';
import WECheckboxInput from '../../../WECheckboxInput/WECheckboxInput';

import styles from './userListPanel.module.css';


function UserListPanel({ children }) {
    return (
        <div className={styles.user_list}>
            {children}
        </div>
    );
}





const Heading = ({ children }) => {
    return (
        <div className={styles.heading}>
            <h2>{children}</h2>
        </div>
    )
}
UserListPanel.Heading = Heading;


const SubHeading = ({ children }) => {
    return (
        <div className={styles.sub_heading}>
            <h4>{children}</h4>
        </div>
    )
}
UserListPanel.SubHeading = SubHeading;



const List = ({ children }) => {
    return (
        <div className={styles.body}>
            <div className={styles.table_container}>
                <ul>
                    {children}
                </ul>
            </div>
        </div>
    )
}
UserListPanel.List = List;



const ListItem = ({ index,isChecked,onChange,item,children,...props }) => {
    return (
        <li className={styles.list_item}>
            <div className={styles.item_cb}>
                <WECheckboxInput index={item.id} id={`list_item_${index}`} isChecked={isChecked} action={onChange} />
            </div>
            <div className={styles.item_details}>
                <span>
                    {item.name}
                </span>
                <span>
                    {item.email}
                </span>
            </div>
        </li>
    )
}
UserListPanel.ListItem = ListItem;



const ListDetails = ({ total,used }) => {
    return (
        <div className={styles.list_details}>
            <span>
                Total Seats: {total}
            </span>
            <span>
                Available Seats: {used}
            </span>
        </div>
    )
}
UserListPanel.ListDetails = ListDetails;
export default UserListPanel;
import React, { useRef,useEffect,useState } from 'react';
import { Save,XCircle } from 'react-bootstrap-icons';
import { createFormDataObj,apiDataFetch } from '../../../../Utilities';
import { useLocalStorage } from '../../../../useLocalStorage';
import loader from '../../../../svg/tail-spin.svg';
import Button from '../../../Button';


import styles from'./AddProductForm.module.css';
import WEButton from '../../../WEButton/WEButton';
import LicenseDetailPanel from '../../Components/LicenseDetailPanel/LicenseDetailPanel';
import UserListPanel from '../../Components/UserListPanel/UserListPanel';
import ThreeColumnView from '../../Components/ThreeColumnView/ThreeColumnView';
import NewCustomerPanel from '../../Components/NewCustomerPanel/NewCustomerPanel';
import useTextField from '../../../../hooks/useTextField';

const inputBorderColor = '#ced4da';

const productTypes = [{value:"app",type:"Application"},{value:"srv",type:"Service"}];
const seatSelectOptions = Array.from(Array(101).keys());



const AddProductForm = ({ formData,submitAction,altAction,readOnly }) => {
    const [session, setSession] = useLocalStorage('session');
    const [userId, setUserId] = useLocalStorage('uid');

    const [isLoading, setIsLoading] = useState(true);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);


    const [checkedStates, setCheckedStates] = useState([]);

    const [currentAgent, setCurrentAgent] = useState({agent:"Agent not set",passphrase:"Agent not set"});
    const [currentProduct, setCurrentProduct] = useState({});
    const [currentLicense, setCurrentLicense] = useState({});

    const [showLicenseForm, setShowLicenseForm] = useState(false);
    const [showServiceForm, setShowServiceForm] = useState(false);
    const [showLicenseSeatDetails, setShowLicenseSeatDetails] = useState(false);




    


    


    let org_id = formData.org_id;
    

    const [products, setProducts] = useState([]);
    const [users, setUsers] = useState([]);
    const [orgAgents, setOrgAgents] = useState([]);
    const [licensedUsers, setLicensedUsers] = useState([]);

    const [numberOfSeats, setNumberOfSeats] = useState({
        total: 0,
        used: 0
    });

    const product_id = useRef("");
    
    
    
    

    

    const hostNameRef = useRef(null);
    const hostpwRef = useRef(null);
    const inPathRef = useRef(null);
    const outPathRef = useRef(null);
    const arcPathRef = useRef(null);
    const hostUserRef = useRef(null);
    const hostPortRef = useRef(null);

    const productTypeSelectRef = useRef(null);

    const fetchProducts = () => {
        let fd = createFormDataObj({
            action: 'list_products',
            session,
            uid: userId
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => { 
            console.table(`API RESPONSE: Success=${result.success}, Message=${result.message}`);    
            if(result.success === true){                
                setProducts(result.data);
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }
    const fetchUsers = () => {
        let fd = createFormDataObj({
            action: 'list_users',
            session,
            uid: userId,
            org_id: org_id
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => { 
            console.table(`API RESPONSE: Success=${result.success}, Message=${result.message}`);    
            if(result.success === true){                
                setUsers(result.data);
                let tmpArr  = new Array(result.data.length).fill(false);
                setCheckedStates([...tmpArr]);
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }
    const fetchAgents = () => {
        let fd = createFormDataObj({
            action: 'get_agents',
            session,
            uid: userId,
            org_id: formData.org_id
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => { 
            console.table(`API RESPONSE: Success=${result.success}, Message=${result.message}`);    
            if(result.success === true){                
                let tmp = result.data;
                setOrgAgents(result.data);
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }

    useEffect(() => {
        fetchProducts();
        fetchUsers();
        fetchAgents();
    },[]);



    const closeModalForm = e => {
        console.log(e);
        console.log('Add user form close form modal method');
      
        if (altAction) {
            altAction();
            return;
        }
        submitAction();
    }
  

    const submitForm = e => {
        e.preventDefault();
        let productType = currentProduct.type;

        let fd;
        
        debugger;
        if (productType === "svc"){

            // fd = {
            //     type: productType,
            //     org_id: formData.org_id,
            //     product_id: currentProduct.id,
            //     seats: 1,
            //     details: JSON.stringify({
            //         agent_id: currentAgent.id,
            //         host: hostNameRef.current.value,
            //         hostname: hostUserRef.current.value,
            //         password: hostpwRef.current.value,
            //         out_path: outPathRef.current.value,
            //         in_path: inPathRef.current.value,
            //         archive_path: arcPathRef.current.value,
            //         port: hostPortRef.current.value
            //     })
            // };
            
        }else if (productType === "app"){
            fd = {
                type: productType,
                org_id: formData.org_id,
                product_id: currentProduct.id,
                seats: numberOfSeats.total,
                details: JSON.stringify(licensedUsers)
            }
        }

       
        if (submitAction){
            submitAction(fd,'create_license');
        }
    }


    


    const productSelectChangeEvent = (e) => {
        console.log(e);
        let selIndx = e.target.selectedIndex;
        if (selIndx === 0){
            setShowLicenseForm(false);
            setShowServiceForm(false);
            setCurrentProduct({});
            return;
        }
        setCurrentProduct(products[selIndx - 1]);
        
        product_id.current = products[selIndx - 1].id;
        if (products[selIndx - 1].type === "app"){
            console.log('APP TYPE PRODUCT SELECTED');
            console.log(products[selIndx - 1]);
            setShowServiceForm(false);
            setShowLicenseForm(true);
            return;
        }
        console.log('SERVICE TYPE PRODUCT SELECTED');
        console.log(products[selIndx - 1]);
        setShowLicenseForm(false);
        setShowServiceForm(true);
        // createLicense(org_id,products[selIndx - 1].id,1);
    }
    const seatCountSelectChangeEvent = (e) => {
        console.log(e);
        console.log(`Number of seats: ${e.target.value}`);
        if (e.target.value === 0 || e.target.value === "default"){
            setNumberOfSeats({...numberOfSeats,total:0});
            setShowLicenseSeatDetails(false);
            return;

        }        
        setNumberOfSeats({...numberOfSeats,total:e.target.value,used:0});        
        setShowLicenseSeatDetails(true);
        // createLicense(org_id,product_id.current,e.target.value);
    }
    const agentSelectChangeEvent = (e) => {
        console.log(e);
        if (e.target.value === "default") {
            setIsDisabled(true);
            return;
        }
        let agent = orgAgents[e.target.value];
        
        setCurrentAgent(agent);
        setIsDisabled(false);
        console.log(`Store agent selected: ${orgAgents[e.target.value].store}`);
    }

    const checkboxClickEvent = (e,eleIndex) => {
        let checkedStatus = checkedStates[eleIndex];        
        let checkboxVal = e.target.dataset.value;

        // let li = e.target.closest('li');
        // let ele = li.querySelector('.license__user__item__loading');
        // let loadingEle = li.querySelector('.license__user__item__loading');
        // let bannerEle = li.querySelector('.license__user__item__banner');
        // li.classList.toggle('is-loading');
        
        
        
        let updatedCheckState = checkedStates.map((check,index) => {
            return index === eleIndex ? !check : check;
        })
        
    
        if (checkedStatus){
            let results = licensedUsers.filter(id => id !== checkboxVal);
            setLicensedUsers([...results]);
            setCheckedStates([...updatedCheckState]);
            numberOfSeats.used--
            let tmpUsed = numberOfSeats.used;
            setNumberOfSeats({...numberOfSeats,...{used:tmpUsed}});
            return;            
        }
                
        if (parseInt(numberOfSeats.total) !== parseInt(numberOfSeats.used)){
            setCheckedStates([...updatedCheckState]);
            setLicensedUsers([...licensedUsers, checkboxVal]);
            numberOfSeats.used++;
            let tmpUsed = numberOfSeats.used;
            setNumberOfSeats({...numberOfSeats,...{used:tmpUsed}});
        }
    }


   
    const [name,nameFieldStatus,setName,setNameFieldStatus] = useTextField();
    const [email,emailFieldStatus,setEmail,setEmailFieldStatus] = useTextField();
    const [password,passwordFieldStatus,setPassword,setPasswordFieldStatus] = useTextField();
    const [isAdmin, setIsAdmin] = useState(false);

    const updateIsAdmin = () => {
        let tmpIsAdmin = isAdmin;
        if (tmpIsAdmin === true){
            setIsAdmin(false);
            return;
        }
        setIsAdmin(true);
    }

    const submitNewUser = (e) => {
        e.preventDefault();
        let fd = createFormDataObj({
            action: "create_user",
            session,
            uid: userId,
            org_id: org_id,
            name,
            email,
            password,
            isAdmin: isAdmin ? "Yes" : "No"
        });

        apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
            console.log('Organization detail view');
            console.log(result);
            debugger;
            if (result.success === true){         
                setUsers([...users,result.data]);
                checkedStates.push(false);
                setName({target:{value:""}});
                setEmail({target:{value:""}});
                setPassword({target:{value:""}});
            } 
        }).catch(error => {
            console.error('Error:',error);
        })
    }

    
    return (
        <form id="add_product_modal-form" className={styles['modal-form']}>

            <div className={styles['select-row']}>
                <div className={styles['select-container']}>
                    <div className={'form-floating pt-2 pb-2'}>
                        <select className="form-select" aria-label="Select product type" onChange={productSelectChangeEvent}>
                            <option value={'default'}>Choose Product</option>
                            {products.map(product => {
                                return (
                                    <option key={product.id} value={product.id}>{product.name}</option>
                                )
                            })}                                    
                        </select>                            
                        <label htmlFor="org-state" aria-label='billing State'>Products</label>
                    </div>
                </div>                
            </div>

            <div className={styles['form-row']}>

               <div className={styles['add_product-content']}>

                    {/* PRODUCT LICENSE FORM HTML */}
                    {showLicenseForm && <div className={styles['product_license-form']}>

                        {/* SECTION - title of the add product form showing  */}
                        <div className='title-container'>
                            <h1>Create a license</h1>
                        </div>

                        {/* SECTION - select number of seats select */}
                        <div className={styles['seat_select-container']}>
                            
                            <div className={`form-floating mt-2 mb-1 ${styles['seat_selector']}`}>
                                <select className="form-select" aria-label="Select organization" onChange={seatCountSelectChangeEvent}>
                                    <option value={'default'}></option>
                                    {seatSelectOptions.map((seat,index) => {
                                        return (
                                            <option key={`${index}-${seat}`} value={seat}>{seat}</option>
                                        )
                                    })}                                    
                                </select>                            
                                <label htmlFor="org-state" aria-label='billing State'>Number of seats</label>
                            </div> 

                        </div>
                        
                        {/* SECTION - license details */}
                        {showLicenseSeatDetails && <div className={styles['license_detail-container']}>

                            <ThreeColumnView 
                                column1={
                                    <LicenseDetailPanel>
                                        <LicenseDetailPanel.Heading>License Details:</LicenseDetailPanel.Heading>
                                        <LicenseDetailPanel.Description>
                                            <h3>Usually billed as follows:</h3>
                                            <ul style={{listStyleType:'disc'}}>
                                                <li>$35 per month per store for one user</li>
                                                <li>$10 per month for each additional user or $15 for 2 additional users</li>
                                            </ul>                                            
                                        </LicenseDetailPanel.Description>
                                    </LicenseDetailPanel>
                                }
                                column2={
                                    <UserListPanel>
                                        <UserListPanel.Heading>Assign user to license</UserListPanel.Heading>
                                        <UserListPanel.SubHeading>Available users</UserListPanel.SubHeading>
                                        <UserListPanel.List>
                                            {
                                                users.map((user,index) => {
                                                    return (
                                                        <UserListPanel.ListItem
                                                            key={user.id}
                                                            index={index} 
                                                            isChecked={checkedStates[index]}
                                                            onChange={(e) => checkboxClickEvent(e,index)}
                                                            item={user}
                                                        />
                                                    )
                                                })
                                            }
                                        </UserListPanel.List>
                                        <UserListPanel.ListDetails 
                                            total={numberOfSeats.total} 
                                            used={numberOfSeats.total - numberOfSeats.used}
                                        />
                                    </UserListPanel> 
                                }
                                column3={
                                    <NewCustomerPanel>
                                        <NewCustomerPanel.Heading>{"Add new user:"}</NewCustomerPanel.Heading>
                                        <NewCustomerPanel.FormContainer>
                                            <NewCustomerPanel.NameField 
                                                id={"user_name"}
                                                placeholder={"Name"}
                                                label={"Name"}
                                                value={name}
                                                action={setName}
                                            />
                                            <NewCustomerPanel.EmailField 
                                                id={"user_email"}
                                                placeholder={"Email"}
                                                label={"Email"}
                                                value={email}
                                                action={setEmail}
                                            />
                                            <NewCustomerPanel.PasswordField
                                                id={"user_password"}
                                                placeholder={"Password"}
                                                label={"Password"}
                                                value={password}
                                                action={setPassword}
                                            />
                                            <NewCustomerPanel.Toggle name={"is_admin"} isChecked={isAdmin} action={updateIsAdmin} label={"Is Admin:"} />
                                            <NewCustomerPanel.SubmitButton onClick={submitNewUser}>
                                                {"Add User"}
                                            </NewCustomerPanel.SubmitButton>
                                        </NewCustomerPanel.FormContainer>
                                    </NewCustomerPanel>
                                }
                            />
                            
                        </div>} 

                    </div>}

                    {showServiceForm && <div className={styles['service_license-form']}>
                        
                        {/* SECTION - title of the add product form showing  */}
                        <div className='title-container'>
                            <h1>Service Details Setup</h1>
                        </div>
                        <div className={styles['service_detail-container']}>
                            
                            <div className={styles['agent_select-container']}>
                                <div className={'form-floating mt-3 mb-3'}>
                                    <select className="form-select" id="org-agents" aria-label="Select store for service" onChange={agentSelectChangeEvent}>
                                        <option value={'default'}></option>
                                        {orgAgents.map((agent,index) => {
                                            return (
                                                <option key={`${agent.id}`} value={index}>{agent.store}</option>
                                            )
                                        })}                                    
                                    </select>                            
                                    <label htmlFor="org-agents" aria-label='Store select field'>Select store for service</label>
                                </div>
                            </div>
                                 
                            <div className={styles['panel-group']}>
                                
                                <div className={styles['panel-one']}>
                                    <div className='title-container mb-3'>
                                        <h1>Service Configuration</h1>
                                    </div>
                                    <div className={'form-floating mb-3'}>
                                        <input ref={hostNameRef} type="text" className="form-control" id="host" placeholder="Host" disabled={isDisabled}/>
                                        <label htmlFor="host" aria-label='Host field'>Host</label>
                                    </div>
                                    <div className={'form-floating mb-3'}>
                                        <input ref={hostPortRef} type="text" className="form-control" id="host-port" placeholder="Host port" disabled={isDisabled}/>
                                        <label htmlFor="host-port" aria-label='Host port field'>Host Port</label>
                                    </div>
                                    <div className={'form-floating mb-3'}>
                                        <input ref={hostUserRef} type="text" className="form-control" id="hostuser" placeholder="Host User" disabled={isDisabled}/>
                                        <label htmlFor="hostuser" aria-label='Host user field'>Host User</label>
                                    </div>
                                    <div className={'form-floating mb-3'}>
                                        <input ref={hostpwRef} type="text" className="form-control" id="hostname-pw" placeholder="Hostname password" disabled={isDisabled}/>
                                        <label htmlFor="hostname-pw" aria-label='Hostname password field'>Hostname Password</label>
                                    </div>
                                    <div className={'form-floating mb-3'}>
                                        <input ref={inPathRef} type="text" className="form-control" id="host-inbox-path" placeholder="Host inbox path" disabled={isDisabled}/>
                                        <label htmlFor="host-inbox-path" aria-label='Host inbox path'>Host Inbox Path</label>
                                    </div>
                                    <div className={'form-floating mb-3'}>
                                        <input ref={outPathRef} type="text" className="form-control" id="host-outbox-path" placeholder="Host outbox path" disabled={isDisabled}/>
                                        <label htmlFor="host-Outbox-path" aria-label='Host Outbox path'>Host Outbox Path</label>
                                    </div>
                                    <div className={'form-floating mb-3'}>
                                        <input ref={arcPathRef} type="text" className="form-control" id="host-archive-path" placeholder="Host archive path" disabled={isDisabled}/>
                                        <label htmlFor="host-archive-path" aria-label='Host archive path'>Host Archive Path</label>
                                    </div>
                                </div>

                                <div className={styles['panel-two']}>
                                    <div className='title-container mb-3'>
                                        <h1>Service API Information</h1>
                                    </div>
                                    <div>
                                        {`Store id: ${currentAgent.agent}`}
                                    </div>
                                    <div>
                                        {`App secret: ${currentAgent.passphrase}`}
                                    </div>
                                    <div>
                                        {`App key: ${currentProduct.app_key}`}
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>}

               </div>

            </div>

            <div className='button-row'>
                {/* FORM BUTTON ROW */}
                
                <div className='button-container'>
                    <Button icon={<XCircle size={16} className='btn-icon' />} title={"Cancel"} className={"danger__button"} onClick={closeModalForm}/>
                </div>    
                <div className='button-container'>
                    <Button icon={<Save size={16} className='btn-icon' />} title={"Save"} className={"accept__button"} onClick={submitForm}/> 
                </div>
                
            </div>

        </form>
    );
}

export default AddProductForm;
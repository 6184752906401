import React, { useState,useEffect } from 'react';
import { useParams,useNavigate } from "react-router-dom";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import usePortal from '../../../../Portal/hooks/usePortal';

import DetailModalPortal from '../../../../Portal/DetailModalPortal';



import { useLocalStorage } from '../../../../useLocalStorage';

import { icon } from '../../../../assets/Icon';
import { apiDataFetch,createFormDataObj,capitalizeFirstLetter } from '../../../../Utilities';

import styles from './billingView.module.css';
import BillingDetailsView from '../../Views/BillingDetailsView/BillingDetailsView';

const Head = ({ children }) => {
    return (
        <div className={styles.head}>
            <h1>{children}</h1>
        </div>
    )
}

const ProductSelect = ({ onChange }) => {
    const [session] = useLocalStorage('session');
    const [userId] = useLocalStorage('uid');
    const [products,setProducts] = useState([]);

    const fetchProducts = () => {
        let fd = createFormDataObj({
            action: 'list_products',
            session,
            uid: userId
        });
        apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {           
            if(result.success === true){
                if (result.data.length > 0) {                    
                    setProducts(result.data);
                }               
            }
        }).catch(error => {
            console.error('Error:',error);
        })        
    }

    useEffect(() => {
        fetchProducts();
    },[])

    return (
        <div className={styles['seat_select-container']}>
            <div className={`form-floating mt-2 mb-1 ${styles['seat_selector']}`}>
                <select id={"billable_product"} className="form-select" aria-label="Default select example" onChange={onChange}>
                <option value={'default'}></option>
                    {products.length > 0 && products.map((product,index) => {
                        return (
                            <option key={index} value={product.id}>{product.name}</option>
                        )
                    })}
                </select>
                <label htmlFor="billable_product" aria-label='Select billable product'>Select Billable Product</label>
            </div>
        </div>
    )
}

const TableView = ({ gridData,action }) => {
    const [session] = useLocalStorage('session');
    const [userId] = useLocalStorage('uid');

    const [colDefs, setColDefs] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState("");
    const [gridColumnApi, setGridColumnApi] = useState("");
    const [currentLicense,setCurrentLicense] = useState("");
    const [coords,updateCoordinates,portalState,togglePortal] = usePortal();
    const navigate = useNavigate();

    const toggleLicenseStatus = (_license_id) => {
        let fd = createFormDataObj({
            action: 'toggle_license',
            session,
            uid: userId,
            id: _license_id
        });        
        apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {            
            if(result.success === true){
                action && action();
            }
        }).catch(error => {
            console.error('Error:',error);
        })
    }

    const submitPayment = (_licenseID,_termDate) => {
        let fd = createFormDataObj({
            action: 'register_payment',
            session,
            uid: userId,
            license_id: _licenseID,
            termDate: _termDate
        });        
        apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {             
            if(result.success === true){
                action && action();
                return;
            }
            if (result.message.includes('terms')){
                alert('Please setup billing terms before trying to make a payment!');
            }else if (result.message.includes('payment')){
                alert('This months payment already collected!');
            }
            
        }).catch(error => {
            console.error('Error:',error);
        })
    }


    let firstClick = false;

    useEffect(() => {
        setRowData(gridData);
        createColumnDefinitions(gridData[0]);
    },[gridData])


    const manageCellRenderer = params => {
        let ele = document.createElement("div");
        ele.classList.add('manage_btn_cell');
        
        let isDisabled = params.data.isTerm === null ? true : false;
        let isDue = params.data.paymentStatus === "due" ? true : false;
        let isActive = params.data.status.toLocaleLowerCase() === "active" ? true : false;

        ele.innerHTML = `
            <button class="${isDue ? styles.row_unpay_button : styles.row_pay_button}" data-action="paid">
                ${icon(24).dollarSign}
            </button>
            <button class="${styles.row_activate_button} ${isActive ? '' : styles.toggled}" data-action="toggle">
                ${icon(24).power}
            </button>`;
        return ele;
    }
    const manageCell = {
        headerName: "Manage",
        cellRenderer: manageCellRenderer,
        editable: false,
        colId: "manage",
        maxWidth: 100,
        sortable: false,
        resizable: false,
        filter: false,
    }

    const checkmarkCellRenderer = params => {
        let ele = document.createElement("div");
        ele.classList.add('manage_btn_cell');
        
        let isDisabled = params.data.isTerm === null ? true : false;
        
        ele.innerHTML = `
            <span class="${isDisabled ? styles.no_terms : styles.has_terms}">
                ${icon(24).largeCheck}
            </span>`;
        return ele;
    }
    const checkmarkCell = {
        headerName: "Has Terms",
        cellRenderer: checkmarkCellRenderer,
        editable: false,
        colId: "hasTerms",
        maxWidth: 100,
        sortable: false,
        resizable: false,
        filter: false,
    }
   
    

    const onRowEditingStopped = params => {
        params.api.refreshCells({
            columns: ["manage"],
            rowNodes: [params.node],
            force: true
        });
    }
    const onRowEditingStarted = params => {
        params.api.refreshCells({
          columns: ["manage"],
          rowNodes: [params.node],
          force: true
        });
    }
    const onRowValueChanged = event => {
        // updateDBRecord(event.node.data);
        event.api.refreshCells({
            columns: ["manage"],
            rowNodes: [event.node],
            force: true
        });
    }
    const onCellClicked = params => {
        let target = params.event.target.closest('button');
        
        
        if (params.column.colId === "manage" && target.dataset.action) {
            let action = target.dataset.action;            
            if (action === "paid") {
                params.api.startEditingCell({
                    rowIndex: params.node.rowIndex,
                    colKey: params.columnApi.getDisplayedCenterColumns()[1].colId
                });
                submitPayment(params.data.id,params.data.termDate);
                
                
            }

            

            if (action === "toggle") {
                let result = window.confirm('Are you sure you want to toggle this license?');
                if (result){                    
                    toggleLicenseStatus(params.data.id)
                }
            }
        }
    }
    const onRowClicked = params => {
       
        let intv = "";
        if (firstClick) {
            setCurrentLicense(params.data.id);
            togglePortal();
            clearTimeout(intv);
        }
        intv = setTimeout(() => {
            firstClick = false;
        },300);
        firstClick = true;
    }
    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1
    }
    const onGridReady = params => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        

    }



    const createColumnDefinitions = (rowObj) => {
        
        let colNames = Object.keys(rowObj);
        let defs = colNames.map(col => {
            let isVisible = false;
            let mWidth = 1000;
            
            if (col.toLocaleLowerCase() === "status"){
                mWidth = 125;
            }
            if (col.toLocaleLowerCase() === "paidon" || col.toLocaleLowerCase() === "duedate"){
                mWidth = 150;
            }
            if (col.toLocaleLowerCase() === 'id' || col.toLocaleLowerCase() === "isterm" ||  col.toLocaleLowerCase() === "termdate" ||  col.toLocaleLowerCase() === "paymentstatus"){
                isVisible = true;
            }
        
    
            let colHead = col.toLocaleLowerCase().includes('orgname') ? 
                'Org Name' : (col.toLocaleLowerCase() === "storename") ? 
                "Store Name" : col.toLocaleLowerCase().includes('isterm') ? 
                "Has Terms" : col.toLocaleLowerCase() === "paidon" ?
                "Last Paid" : col.toLocaleLowerCase() === "duedate" ? 
                "Due Date" : capitalizeFirstLetter(col);
                

            return {
                headerName: colHead,
                field: col,
                hide: isVisible,
                editable: false,
                maxWidth: mWidth
            }
        });
        defs.push(checkmarkCell)
        defs.push(manageCell);
        setColDefs(defs);
    }

    const detailPanelAction = (e) => {
        
        togglePortal();        
        action && action();       
    }

    return (
        <div className={styles.grid_row}>
            <div className="ag-theme-alpine" style={{height: '100%', width: '100%'}}>
                <AgGridReact
                    onRowEditingStopped={onRowEditingStopped}
                    onRowEditingStarted={onRowEditingStarted}
                    onRowValueChanged={onRowValueChanged}
                    onCellClicked={onCellClicked}
                    onRowClicked={onRowClicked}
                    stopEditingWhenCellsLoseFocus={true}
                    editType="fullRow"
                    animateRows={true}
                    suppressClickEdit={true}
                    columnDefs={colDefs}
                    defaultColDef={defaultColDef}
                    rowSelection={'single'}
                    onGridReady={onGridReady}
                    rowData={rowData}
                />
            </div>
            {portalState && 
                <DetailModalPortal>
                    <BillingDetailsView 
                        coords={coords}
                        updateCoords={e => updateCoordinates(e,{})}
                        action={detailPanelAction}
                        license={currentLicense}
                    />
                </DetailModalPortal>
            }
        </div>
    );
}

function BillingView() {
    const [session] = useLocalStorage('session');
    const [userId] = useLocalStorage('uid');
    const [currentProduct,setCurrentProduct] = useState("");
    const [tableData,setTableData] = useState([]);
    const [billingTableVisible,setBillingTableVisible] = useState(false);

    

    

    const fetchBillableAgents = () => {
        if (currentProduct === "") return ;
        let fd = createFormDataObj({
            action: 'get_billable_agents',
            session,
            uid: userId,
            product_id: currentProduct
        });        
        apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
            if(result.success === true){                
                if (result.data.length > 0) {                    
                    setTableData([...result.data]);
                    setBillingTableVisible(true);
                }               
            }
        }).catch(error => {
            console.error('Error:',error);
        })
    }
    
    useEffect(() => {
        if (currentProduct === "" || currentProduct === null) return;
        fetchBillableAgents();
    },[currentProduct])

    const onProductSelection = (e) => {
        if (e.target.value === 0 || e.target.value === "default") {
            setBillingTableVisible(false);
            return;
        }
        setCurrentProduct(e.target.value);              
    }

    return (
        <div className={styles.billing_view}>
            <Head>Billing Center</Head>
            <ProductSelect onChange={onProductSelection}/>
            {billingTableVisible && <TableView gridData={tableData} action={fetchBillableAgents} />}
            
        </div>
    );
}

export default BillingView;
import React, { useState } from 'react';

import styles from './buttonGroup.module.css';

function ButtonGroup({ children }) {
    const [activeBtn, setActiveBtn] = useState();


    const buttonActionEvent = (e) => {
        if (e.target?.type !== 'button') return;  
        if (activeBtn){
            activeBtn.classList.remove(styles.active_button);
        }
        e.target.classList.add(styles.active_button);
        setActiveBtn(e.target);
        // action(e.target.value.toLowerCase());
    }

    return (
        <GroupContainer>
            <Wrapper>
                {children}
            </Wrapper>
        </GroupContainer>
    );
}

const GroupContainer = ({ children }) => {
    return (
        <div className={styles.container}>
            {children}
        </div>
    )
}

const Wrapper = ({ children }) => {
    return (
        <div className={styles.wrapper}>
            {children}
        </div>
    )
}

const Button = ({ name,action }) => {
    

    const onButtonClick = (e) => {
        if (e.target.localName !== "button") return;        
        let button = e.target;
        button.classList.add(styles.active_button);
        action && action(button.value);
        if (button.nextElementSibling === null) {
            button.previousElementSibling.classList.remove(styles.active_button);
            return;
        }
        button.nextElementSibling.classList.remove(styles.active_button);        
    }

    return (
       
            <button
                type="button" 
                id={name}             
                value={name}
                onClick={onButtonClick}
            >
                {name}
            </button>                                                            
        
    )
}
ButtonGroup.Button = Button;

export default ButtonGroup;
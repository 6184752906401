import React, { useState,useRef } from 'react';
import { PlusSquare } from 'react-bootstrap-icons';
import { dateTool } from '../../../../Utilities';
import ButtonGroup from '../../../ButtonGroup/ButtonGroup';
import DatePicker from '../../../DatePicker/DatePicker';
import WEButton from '../../../WEButton/WEButton';


/* COMPONENT CSS IMPORT */
import styles from './billingDetailPanel.module.css';




function BillingDetailPanel({ children }) {

    const [disableBilling, setDisableBilling] = useState(false);


    
    const [termStart, setTermStart] = useState('1983-04-20 00:00:00');
    const [termEnd, setTermEnd] = useState('1983-07-10 00:00:00');
    const [salePrice, setSalePrice] = useState('');
    const [payMethod, setPayMethod] = useState('');
    const [paymentDate, setPaymentDate] = useState("");
    const [didCollect, setDidCollect] = useState(false);
    
    
    
    
    


    const monthTermBtnRef = useRef(null);
    const yearTermBtnRef = useRef(null);
    const dueDateFieldRef = useRef(null);

    const termButtonClickAction = (e) => {
        e.preventDefault();
        console.log(e);
        console.log(`Term ${e.target.id} button clicked`);
        if (e.target?.type !== 'button') return;
            
        // setTermType(e.target.value);
       
        if (e.target.classList.contains(styles["active-button"])){
            e.target.classList.remove(styles['active-button']);
            // setTermType('');
            return;
        }

        e.target.classList.add(styles["active-button"]);

        if (e.target.id === "month-term"){
            document.getElementById("year-term").classList.remove(styles["active-button"]);
            return;
        }

        document.getElementById("month-term").classList.remove(styles["active-button"]);
    }

    const startDateChangeEvent = (e) => {
        console.log(e);
        console.log('Start date change event');        
        setTermStart(`${e.target.value} 00:00:00`);        
    }
    const endDateChangeEvent = (e) => {
        console.log(e);
        console.log('End date change event');
        setTermEnd(`${e.target.value} 00:00:00`);
    }
    const salePriceTFChangeEvent = (e) => {
        setSalePrice(e.target.value);
    }

    const didCollectCheckEvent = (e) => {
        console.log(e);
        console.log('Did Collect');
        if (didCollect){
            setDidCollect(false);
            return;
        }
        setDidCollect(true);
    }
    
    const paymentDateChangeEvent = (e) => {

    }
    const payMethodChangeEvent = (e) => {
        setPayMethod(e.target.value);
    }




    const activatePanelBtnEvent = (e) => {
        if (disableBilling === false) return;
        setDisableBilling(false);
        monthTermBtnRef.current.classList.add(styles['active-button']);
        setTermStart(dateTool.todaysDate(false));
        setTermEnd(dateTool.todaysDate(false));
        setPaymentDate(dateTool.todaysDate(false));
    }
    const submitPaymentBtnAction = (e) => {

    }

    return (                           
        <PanelContainer>
            {children}
        </PanelContainer>
    );
}


const PanelContainer = ({ children }) => {
    return (
        <div className={styles.panel_container}>
            {children}
        </div>
    )
}

const Heading = ({ children }) => {
    return (
        <div className={styles['heading']}>
              {children}
        </div>        
    )
}
BillingDetailPanel.Heading = Heading;

const FormContainer = ({ children }) => {
    return (
        <div className={styles.form_container}>
            <div className={styles.form_fields}>
                {children}
            </div>
        </div>
    )
}
BillingDetailPanel.FormContainer = FormContainer;

const FeeTextField = ({ value,action,label,...props  }) => {
    return (
        <div className={styles.fee_text_field}>
            <div className={'form-floating'}>
                <input 
                    type="text" 
                    value={value} 
                    className="form-control" 
                    id={props.id} 
                    placeholder={props.placeholder} 
                    onChange={action} 
                />
                <label 
                    htmlFor={props.id} 
                    aria-label={`${label} text field`}
                >
                    {label}
                </label>
            </div>
        </div>
    )
}
BillingDetailPanel.FeeTextField = FeeTextField;

const PaymentTypeSelect = ({ action,label,children,...props }) => {
    return (
        <div className={styles.payment_method_wrapper}>
            <div className={`form-floating mt-2 mb-1 ${styles.pay_method_select}`}>
                <select 
                    className="form-select" 
                    aria-label={`${label}`}
                    id={props.id} 
                    onChange={action}
                    defaultValue={props.defaultValue}
                >
                    {children}
                </select>
                <label htmlFor={props.id} aria-label={label}>
                    {label}    
                </label>
            </div>
        </div>
    )
}
BillingDetailPanel.PaymentTypeSelect = PaymentTypeSelect;

const Button = ({ action,children }) => {
    return (
        <div className={styles.button_row}>            
            <WEButton onClick={action}>
                {children}
            </WEButton>
        </div>
    )
}
BillingDetailPanel.Button = Button;

const Label = ({ children }) => {
    return (
        <div className={styles.label}>
            <div>
                {children}
            </div>
        </div>
    )
}
BillingDetailPanel.Label = Label;

BillingDetailPanel.ButtonGroup = ButtonGroup;
BillingDetailPanel.DatePicker = DatePicker;




export default BillingDetailPanel;
import React from 'react';


import styles from './weToggle.module.css';


// Checkbox parent container component
const CheckBox = ({ children }) => {
    return (
        <div className={styles.checkbox}>
            <div className={styles.wrapper}>
                {children}
            </div>
        </div>
    )
}

// Checkbox label component
const Label = ({ children }) => {
    return (
        <div className={styles.label}>
            <h3>{children}</h3>
        </div>
    )
}
CheckBox.Label = Label;

// Input wrapper component 
const InputWrapper = ({ children }) => {
    return (
        <div className={styles.input_wrapper}>
            {children}
        </div>
    )
}
CheckBox.InputWrapper = InputWrapper;

// Input component
const Input = ({ name,isChecked,action,children,...props }) => {
    return (
        <label className={styles.input} htmlFor={"we-toggle-input"}>
            <input
                type="checkbox"
                id={"we-toggle-input"}
                className='we-cb-input'
                name={name}
                checked={isChecked}
                onChange={action}
                disabled={false}
            />
            <span className={styles.checkmark}></span>
        </label>
    )
}
CheckBox.Input = Input;


// Checkbox export component 
const WEToggle = ({ name,checked,onChange,label,disabled=false }) => {

    return (
        <CheckBox>
            <CheckBox.Label>
                {label}
            </CheckBox.Label>
            <CheckBox.InputWrapper>
                <CheckBox.Input name={name} isChecked={checked} action={onChange} />
            </CheckBox.InputWrapper>
        </CheckBox>
    );
};

export default WEToggle;
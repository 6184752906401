import React, { useEffect, useState } from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useNavigate } from "react-router-dom";

import icons from '../../../../AppIcons';
import { capitalizeFirstLetter } from '../../../../Utilities';

import { PlusSquare } from 'react-bootstrap-icons';

import styles from './userTable.module.css';

function UserTable({ tableData,size,action,...props }) {
    const [rowData, setRowData] = useState(tableData);
    const [colDefs, setColDefs] = useState([]);
    const [gridApi, setGridApi] = useState();
    const [gridColumnApi, setGridColumnApi] = useState();
    const navigate = useNavigate();

    let firstClick = false;

    const deleteUserDBRecord = () => {
        
    }
    const updateUserDBRecord = (recordObj) => {
        
    }

    useEffect(() => {
        if (tableData.length === 0) return;
        createColumnDefs(tableData[0]);
        setRowData(tableData);

    },[tableData]);

    const onGridReady = params => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        setColDefs([]);
    }

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1
    }

    const manageCellRenderer = params => {
        let ele = document.createElement("div");
        ele.classList.add('manage_btn_cell');
        let editingCells = params.api.getEditingCells();

        let isCurrentRowEditing = editingCells.some((cell) => {
            return cell.rowIndex === params.node.rowIndex;
        });

        if (isCurrentRowEditing) {
           ele.innerHTML = `
                <button class="row_manage_btn" data-action="update">${icons.save}</button>
                <button class="row_manage_btn" data-action="cancel">${icons.cancel}</button>
            `;
        } else {
            ele.innerHTML = `
                <button class="row_manage_btn" data-action="view">${icons.view}</button>
                <button class="row_manage_btn" data-action="edit">${icons.edit}</button>
                <button class="row_manage_btn" data-action="delete">${icons.delete}</button>
            `;
        }
        return ele;
    }
    const manageDeleteCellRenderer = params => {
        let ele = document.createElement("div");
        ele.classList.add('manage_btn_cell');
        ele.innerHTML = `
            <button class="row_manage_btn" title="Copy agent" data-action="copy">${icons.clipboardCopy}</button>
            <button class="row_manage_btn" title="Delete agent" data-action="delete">${icons.delete}</button>
        `;
        return ele;
    }
    const manageCell = {
        headerName: "Manage",
        cellRenderer: manageCellRenderer,
        editable: false,
        colId: "manage",
        maxWidth: 150,
        sortable: false,
        resizable: false,
        filter: false,
    }
    const manageDeleteCell = {
        headerName: "Manage",
        cellRenderer: manageDeleteCellRenderer,
        editable: false,
        colId: "manage",
        maxWidth: 125,
        sortable: false,
        resizable: false,
        filter: false,
    }
    function cellEditorSelector(params) {
        return {
            component: 'agSelectCellEditor',
            params: {
              values: ['Y', 'N'],
            },
          };
    }

    const createColumnDefs = (rowObj) => {
        let colNames = Object.keys(rowObj);
        
        let defs = colNames.map(col => {
            let isVisible = false;
            if (col.toLocaleLowerCase() === "org_id") {
                isVisible = true;
            }
            let editor = col.toLowerCase().includes('isadmin') ? cellEditorSelector : "";
            let header = (col.toLocaleLowerCase() === "org_id") ? "Org Id" : col.toLocaleLowerCase() === "isadmin" ? "Admin" : col.toLocaleLowerCase() === "org_name" ? "Organization" : capitalizeFirstLetter(col);
            let retObj = {
                headerName: header,
                field: col,
                editable: col.toLowerCase().includes('id') ? false : true,
                cellEditorSelector: editor,
                hide: isVisible
            }
            if ((col.toLocaleLowerCase() === "org_id") || (col.toLocaleLowerCase() === "id") || (col.toLocaleLowerCase() === "isadmin")) {
                retObj['maxWidth'] = 125;
            }

            return retObj;
        });
        defs.push(manageCell);
        setColDefs(defs);
    }

    const onRowEditingStarted = params => {
        
        params.api.refreshCells({
          columns: ["manage"],
          rowNodes: [params.node],
          force: true
        });
    }
    const onRowEditingStopped = params => {
        params.api.refreshCells({
            columns: ["manage"],
            rowNodes: [params.node],
            force: true
        });
    }
    const onRowValueChanged = event => {
        updateUserDBRecord(event.node.data);
        event.api.refreshCells({
            columns: ["manage"],
            rowNodes: [event.node],
            force: true
        });
    }

    const onCellClicked = params => {
        let target = params.event.target.closest('button');
        if (params.column.colId === "manage" && target.dataset.action) {
            
            let action = target.dataset.action;
        
            if (action === "edit") {
              params.api.startEditingCell({
                rowIndex: params.node.rowIndex,
                colKey: params.columnApi.getDisplayedCenterColumns()[2].colId
              });
            }

            if (action === "copy") {
                let editingCell = params.columnApi.getDisplayedCenterColumns()[0].colId;
                let agent = params.node.data.agent;
                navigator.clipboard.writeText(agent);
                let cell = target.parentElement.parentElement.parentElement.parentElement;
                let ele = document.createElement('div');
                ele.classList.add('copied__alert');
                ele.innerText = "Copied!";
                cell.appendChild(ele);
                let intv = setTimeout(() => {
                    ele.classList.add('show__alert');
                    clearTimeout(intv);   
                }, 100);
                let intv2 = setTimeout(() => {
                    ele.classList.remove('show__alert');
                    clearTimeout(intv2);
                }, 1500);
                let intv3 = setTimeout(() => {
                    ele.remove();
                    clearTimeout(intv3);
                }, 1800);
              }

            if (action === "update") {
            
            params.api.stopEditing(false);
            }


            if (action === "view") {
                // setModal(modalForType('user',params.node.data.name,params.node.data,true));
                // setFormModalShowing(true);
            }
            if (action === "delete") {
                let result = window.confirm('Are you sure you want to delete this record?');
                if (result) {
                    deleteUserDBRecord(params.node.data.id);
                    params.api.applyTransaction({
                        remove: [params.node.data]
                    });
                }
            }
            if (action === "cancel") {
                params.api.stopEditing(true);
            }
        }
    }
    const onRowClicked = params => {
        let intv = "";
        if (firstClick) {
            navigate(`/admin/users/${params.data.id}`);    
            clearTimeout(intv);
        }
        intv = setTimeout(() => {
            firstClick = false;
        },300);
        firstClick = true;
    }



    const addUserBtnEvent = () => {

    }

    return (
        <div className={styles.container}>
            <div className={styles.action_row}>
                <div className={styles.heading}>Add store user:</div>
                <PlusSquare size={30} onClick={action}/>
            </div>
            <div className={styles.table_row}>
                <div className="ag-theme-alpine" style={{height: '100%', width: '100%'}}>
                    <AgGridReact
                        onRowEditingStopped={onRowEditingStopped}
                        onRowEditingStarted={onRowEditingStarted}
                        onRowValueChanged={onRowValueChanged}
                        onCellClicked={onCellClicked}
                        onRowClicked={onRowClicked}
                        stopEditingWhenCellsLoseFocus={true}
                        editType="fullRow"
                        animateRows={true}
                        suppressClickEdit={true}
                        columnDefs={colDefs}
                        defaultColDef={defaultColDef}
                        rowSelection={'single'}
                        onGridReady={onGridReady}
                        rowData={rowData}
                    />
                </div>
            </div>
        </div>
    );
}

export default UserTable;
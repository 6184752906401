import React from 'react';

import styles from './licenseDetailPanel.module.css';

const PanelContainer = ({ children }) => {
    return (
        <div className={styles.panel_container}>
            {children}
        </div>
    )
}

const Heading = ({ children }) => {
    return (
        <div className={styles.heading}>
            {children}
        </div>
    )
}
LicenseDetailPanel.Heading = Heading;

const Description = ({ children }) => {
    return (
        <div className={styles.description}>
            <span>
                {children}
            </span>
        </div>
    )
}
LicenseDetailPanel.Description = Description;

function LicenseDetailPanel({ children }) {
    return (
        <PanelContainer>
            {children}
        </PanelContainer>
    );
}

export default LicenseDetailPanel;
import React, { useState } from 'react';
import { ListOl,QuestionLg,ExclamationLg,CaretDown } from 'react-bootstrap-icons';
import icons from '../../AppIcons';
import appLogo from '../../images/integratorIcon.png';
import { softwareDownloadViewData } from '../../Utilities';
import './SoftwareDownloadView.css';

const SoftwareDownloadView = ({ title,subtitle,overview,versions,fileLink }) => {
    const [downloadVersion, setDownloadVersion] = useState("");
    const [fileName, setFileName] = useState("");    
    
    const downloadBtnEvent = e => {
        console.log(e);
        console.log(`${softwareDownloadViewData.integrator.title} software download view download button click event`);
        window.location.href = 'https://integrator.gldstools.com/SMS%20Integrator%20Setup.msi';
    }
    const btnOptionClickEvent = e => {
        console.log(e);
        console.log('Download button option button click event');
        console.log(e.target.dataset.version);
        setDownloadVersion(`Ver ${e.target.dataset.version}`);
        setFileName(e.target.dataset.filename);
    }

 
    return (
        <div className='software__download__view'>
            <div className='title'>
                <h3>{softwareDownloadViewData.integrator.title}</h3>
                <h4>{softwareDownloadViewData.integrator.subtitle}</h4>
            </div>
            <div className='content'>
                <div id='dwn__panel' className='download__panel'>
                    <div className='download__panel__title'>
                        <h3>Download</h3>
                    </div>
                    <div className='app__logo__container'>
                        <img src={appLogo} className='app__logo__img'/>
                    </div>
                    <div className='file__name'>
                        <h4>{`${fileName} ${downloadVersion}`}</h4>
                    </div>
                    <div className='file__link__container'>
                        
                        <div class="btn-group download_btn">
                            <button id="version__download__btn" type="button" class="btn btn-success" onClick={downloadBtnEvent} >
                                {`Download ${downloadVersion}`}
                            </button>
                            <button id="version__download__option__btn" type="button" class="btn btn-success dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" >
                                <span class="visually-hidden">Toggle Dropdown</span>
                            </button>
                            <ul class="dropdown-menu" onClick={btnOptionClickEvent} >
                                {softwareDownloadViewData.integrator.versions.map((ver,index) => {
                                    let curRel = ver.currentRelease;
                                    return (
                                        <li key={index} className={`dropdown-item ${ver.currentRelease ? "current" : ""}`} data-link={ver.fileLink} data-version={ver.release} data-filename={ver.fileName}>{`Version ${ver.release}`}</li>
                                    )
                                })}
                            </ul>
                        </div>                        
                    </div>
                </div>
                <div id='doc__panel' className='documentation__panel'>
                    <div className='doc__panel__title'>
                        <h3>Documentation</h3>
                    </div>
                    <div className='overview'>
                        <div className='overview__title'>
                            <h4>Overview</h4>
                        </div>
                        <div className='overview__content'>
                            <p>{softwareDownloadViewData.integrator.overview}</p>
                        </div>                    
                    </div>
                    <div className='documentation__options'>
                        <h4>SMS Integrator Service</h4>
                        <ol>
                            <li>Download and Run SMSIntegratorService.msi.</li>
                            <li>This will install and start the service.</li>
                            <li>Find and start the Service Monitor program in the Windows Start menu.</li>
                            <li>This will start the service tray program.</li>
                            <li>Open the SMS Integrator Service tray program.</li>
                            <li>Open the settings page, paste agent string into the agent field and click Save Configuration.</li>
                            <li>Stop the service, wait 10 seconds, start the service.</li>
                        </ol>
                        {/* <div className='option__selection'>
                            <div><ListOl size={16} /> <span className='mid'>Installation Steps</span><span><CaretDown size={16} /></span></div>
                            <div><ExclamationLg size={16} /> <span className='mid'>Troubleshooting</span><span><CaretDown size={16} /></span></div>
                            <div><QuestionLg size={16} /> <span className='mid'>FAQ's</span><span><CaretDown size={16} /></span></div>
                        </div> */}
                    </div>
                </div>
            </div>            
        </div>
    );
}

export default SoftwareDownloadView;
import React, { useState,useRef,useContext,useEffect } from 'react';
import { useParams,useNavigate } from "react-router-dom";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Search,XLg } from 'react-bootstrap-icons';
import { AppContext } from '../../../AppContext/AppContext';
import { useLocalStorage } from '../../../useLocalStorage';
import icons from '../../../AppIcons';
import { capitalizeFirstLetter,states,createFormDataObj,apiDataFetch } from '../../../Utilities';
import './routeCss/homeView.css';
import GLDSFormModal from '../../GLDSFormModal/GLDSFormModal';
import AddAgentForm from '../Forms/AddAgentForm/AddAgentForm';
import AddProductForm from '../Forms/AddProductForm/AddProductForm';

import AddOrganizationForm from '../Forms/AddOrganizationForm/AddOrganizationForm';
import AddUserForm from '../Forms/AddUserForm/AddUserForm';





const HomeView = (props) => {
    const { state } = useContext(AppContext);
    const [session, setSession] = useLocalStorage('session');
    const [userId, setUserId] = useLocalStorage('uid');
    const [privilage, setPrivilage] = useLocalStorage('privilage');
    let urlParams = useParams();
    let navigate = useNavigate();
    const [searchFieldIcon, setSearchFieldIcon] = useState(<Search size={18} />);

    const homeViewRef = useRef(null);
    const [formModalShowing, setFormModalShowing] = useState(false);
    const [modal, setModal] = useState();


    const [colDefs, setColDefs] = useState([]);
    const [gridApi, setGridApi] = useState();
    const [gridColumnApi, setGridColumnApi] = useState();
    const [rowData, setRowData] = useState();
    const searchInputRef = useRef(null);
    const searchInputBtnRef = useRef(null);
    let searchData = [];
    let firstClick = false;

    

    const organizationAgents = (orgs) => {
        let fd = new FormData();
        fd.append('action','list_agents');
        fd.append('session',session);
        fd.append('uid',userId);
        debugger;
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => {
            debugger;
            if(result.success === true){
                
                let tmpOrgs = orgs;
                let tmpAgents = result.data;
                let nOrgs = [];
                orgs.forEach(org => {
                    let porg = 0;
                    if (tmpAgents.length === 0){
                        porg = {...{id:org.id,agents:0},...org};    
                    }else{
                        let agentCount = tmpAgents.filter(agent => agent.org_id === org.id);
                        porg = {...{id:org.id,agents:agentCount.length},...org};
                    }
                    nOrgs.push(porg);
                });
                
                


                setRowData(nOrgs);                
                if (nOrgs.length > 0) {                    
                    createColumnDefinitions(nOrgs[0]);
                }
                return;
            }
            console.log("test");
            debugger;
            createColumnDefinitions(orgs[0]);
            setRowData(orgs);
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }

    /**
     * Effect is triggered when the view parameter in URL changes
     * causing a fetch request to get table data
     */
    useEffect(() => {
        searchInputRef.current.value = "";
        let actionType = `list_${urlParams.view.toLowerCase()}`;        
                
              
        apiDataFetch(
            window.API_URL,
            {
                method:"POST",
                body: createFormDataObj({
                    action:actionType,
                    session:session,
                    uid:userId
                })
            }
        ).then(result => {
            
            if (result.success === false) {
                setColDefs([]);
                setRowData([]);
                gridApi.setRowData(rowData);
                
                return;
            }           
            if(result.success === true){
                
                
                if (result.data.length === 0) {
                
                    setColDefs([]);
                    setRowData([]);
                    gridApi.setRowData(rowData);
                    return;
                }
                if(urlParams.view.toLowerCase() === 'organizations'){
                    organizationAgents(result.data)
                    return;
                }
                
                setRowData(result.data);
                
                if (result.data.length > 0) {                    
                    createColumnDefinitions(result.data[0]);
                }
                
                // console.log(result.message);
                return;
            }
        })                        
    },[urlParams.view]);


    // Record manipulation methods
    const deleteDBRecord = (recordId) => {
        
        let actionType = `delete_${urlParams.view.toLowerCase().slice(0, -1)}`;
        const fd = new FormData();
        fd.append('action',actionType);
        fd.append('session',session);
        fd.append('uid',userId);
        fd.append('delete_id',recordId);
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => {
            console.log(`Response message-> ${result.message}`);
            if(result.success === true){
                console.log(result.message);
                return;
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }
    const updateDBRecord = (recordObj) => {
        
        let actionType = `update_${urlParams.view.toLowerCase().slice(0,-1)}`;
        const fd = new FormData();
        fd.append('action',actionType);
        fd.append('session',session);
        fd.append('uid',userId);
        for (const key in recordObj) {
            if (Object.hasOwnProperty.call(recordObj, key)) {
                let record = recordObj[key];
                if (key.toLocaleLowerCase() === 'id') {
                    record = parseInt(record);
                    fd.append('update_id',record);
                    continue;
                }
                console.log(`Key: ${key} Value: ${record}`);
                fd.append(key,record);
            }
        }
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => {
            console.log(`Request response: ${result.message}`);
            if(result.success === true){
                console.log(result.message);
                return;
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }


    // creates column data mapping and attributes array
    const createColumnDefinitions = (rowObj) => {
        let colNames = Object.keys(rowObj);
        let defs = colNames.map(col => {
            let isVisible = false;
            let mWidth = 1000;
            
            if ((col.toLocaleLowerCase() === "id") || (col.toLocaleLowerCase() === "isadmin")) {
                mWidth = 125;
            }
            if (col.toLocaleLowerCase() === 'org_id' || col.toLocaleLowerCase() === 'product'){
                isVisible = true;
            }
            let editor = col.toLowerCase().includes('isadmin') ? 
                        cellEditorSelector : col.toLowerCase().includes('state') ? 
                        cellEditorStateSelector : "";
            let currencyFormat = col.toLocaleLowerCase().includes('srp') ? currencyFormatter : "";
            let colHead = col.toLocaleLowerCase().includes('srp') ? 'Suggested Retail Price ($)' : (col.toLocaleLowerCase() === "org_id") ? "Org Id" : col.toLocaleLowerCase() === "isadmin" ? "Admin" : col.toLocaleLowerCase() === "org_name" ? "Organization" : capitalizeFirstLetter(col);
            return {
                headerName: colHead,
                field: col,
                editable: col.toLowerCase().includes('id') ? false : urlParams.view.toLowerCase().includes('agent') ? false : true,
                cellEditorSelector: editor,
                sort: (col.toLowerCase() === "id") ? 'asc' : '',
                valueFormatter: currencyFormat,
                hide: isVisible,
                maxWidth: mWidth
            }
        });
        if (urlParams.view.toLowerCase() === 'agents'){
            defs.push(copyButtonsCell);
        }else {
            defs.push(editButtonsCell);
        }
        setColDefs(defs);
    }


    // Button cell renderer function and button cell object
    const editButtonsCellRenderer = params => {
        let ele = document.createElement("div");
        ele.classList.add('manage_btn_cell');
        let editingCells = params.api.getEditingCells();

        let isCurrentRowEditing = editingCells.some((cell) => {
            return cell.rowIndex === params.node.rowIndex;
        });

        if (isCurrentRowEditing) {
           ele.innerHTML = `
                <button class="row_manage_btn" data-action="update">${icons.save}</button>
                <button class="row_manage_btn" data-action="cancel">${icons.cancel}</button>
            `;
        } else {
            ele.innerHTML = `
                <button class="row_manage_btn" data-action="view">${icons.view}</button>
                <button class="row_manage_btn" data-action="edit">${icons.edit}</button>
                <button class="row_manage_btn" data-action="delete">${icons.delete}</button>
            `;
        }
        return ele;
    }
    const editButtonsCell = {
        headerName: "Manage",
        cellRenderer: editButtonsCellRenderer,
        editable: false,
        colId: "manage",
        maxWidth: 150,
        sortable: false,
        resizable: false,
        filter: false,
    }
    const copyButtonsCellRenderer = params => {
        let ele = document.createElement("div");
        ele.classList.add('manage_btn_cell');
        ele.innerHTML = `
            <button class="row_manage_btn" title="Copy agent" data-action="copy">${icons.clipboardCopy}</button>
            <button class="row_manage_btn" title="Delete agent" data-action="delete">${icons.delete}</button>
        `;
        return ele;
    }   
    const copyButtonsCell = {
        headerName: "Manage",
        cellRenderer: copyButtonsCellRenderer,
        editable: false,
        colId: "manage",
        maxWidth: 125,
        sortable: false,
        resizable: false,
        filter: false,
    }

    // Cell formatting function
    const currencyFormatter = params => {
        let val = parseFloat(params.value).toFixed(2)
        return `$${val}`;
    }

    
    // Default column definition
    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1
    };

    // Custom cell editing functions
    function cellEditorSelector(params) {
        return {
            component: 'agSelectCellEditor',
            params: {
              values: ['Y', 'N'],
            },
        };
    }
    function cellEditorStateSelector(params) {
        return {
            component: 'agSelectCellEditor',
            params: {
              values: states,
            },
          };
    }

    const rowClicked = params => {
        if(urlParams.view.toLocaleLowerCase() === 'products' || urlParams.view.toLocaleLowerCase() === 'agents') return;
        let intv = "";
        if (firstClick) {
            navigate(`/admin/${urlParams.view.toLowerCase()}/${params.data.id}`);    
            clearTimeout(intv);
        }
        intv = setTimeout(() => {
            firstClick = false;
        },300);
        firstClick = true;
    }

    const onCellClicked = params => {
        let target = params.event.target.closest('button');
        
        
        if (params.column.colId === "manage" && target.dataset.action) {
            
            let action = target.dataset.action;
        
            if (action === "edit") {
              params.api.startEditingCell({
                rowIndex: params.node.rowIndex,
                colKey: params.columnApi.getDisplayedCenterColumns()[2].colId
              });
            }

            if (action === "copy") {
                let editingCell = params.columnApi.getDisplayedCenterColumns()[0].colId;
                let agent = params.node.data.agent;
                navigator.clipboard.writeText(agent);
                let cell = target.parentElement.parentElement.parentElement;
                let ele = document.createElement('div');
                ele.classList.add('copied__alert');
                ele.innerText = `Agent Copied!`;
                cell.appendChild(ele);
                let intv = setTimeout(() => {
                    ele.classList.add('show__alert');
                    clearTimeout(intv);   
                }, 100);
                // let intv2 = setTimeout(() => {
                //     ele.classList.remove('show__alert');
                //     clearTimeout(intv2);
                // }, 1500);
                let intv3 = setTimeout(() => {
                    ele.remove();
                    clearTimeout(intv3);
                }, 3100);
              }

            if (action === "view") {
                navigate(`/admin/${urlParams.view.toLowerCase()}/${params.data.id}`);
                
            }
      
            if (action === "delete") {
                let result = window.confirm('Are you sure you want to delete this record?');
                if (result) {
                    deleteDBRecord(params.node.data.id);
                    params.api.applyTransaction({
                        remove: [params.node.data]
                    });
                }
            }
      
            if (action === "update") {
                
              params.api.stopEditing(false);
            }
      
            if (action === "cancel") {
              params.api.stopEditing(true);
            }
        }
    }

    // Grid row event methods
    const onRowEditingStarted = params => {
        
        params.api.refreshCells({
          columns: ["manage"],
          rowNodes: [params.node],
          force: true
        });
    }
    const onRowEditingStopped = params => {
        params.api.refreshCells({
            columns: ["manage"],
            rowNodes: [params.node],
            force: true
        });
    }
    const onRowValueChanged = event => {
        updateDBRecord(event.node.data);
        event.api.refreshCells({
            columns: ["manage"],
            rowNodes: [event.node],
            force: true
        });
    }

    // Grid is ready event
    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi); 
    }

    // Search input methods
    const searchTableEvent = (e) => {
        // console.log(e);
        // console.log('Search table event');
        if (searchInputBtnRef.current.dataset.icon === 'search') {
            setSearchFieldIcon(<XLg size={18} />);
            searchInputBtnRef.current.dataset.icon = "clear";
        }else{
            searchInputRef.current.value = "";
            setSearchFieldIcon(<Search size={18} />);
            searchInputBtnRef.current.dataset.icon = "search";
        }
        let searchTerm = searchInputRef.current.value;
        if (searchTerm === ""){
            gridApi.setRowData(rowData);
            return;
        }
        searchData = rowData.filter((row) => {
            for (const key in row) {
                const rowValue = row[key];
                if (rowValue) {
                    if (rowValue.toString().toLowerCase().includes(searchTerm.toLowerCase())){
                        return row;
                    }
                }                
            }
        });
        gridApi.setRowData(searchData);
    }
    const searchInputKeyDownEvent = (e) => {
        if (e.code.toLowerCase() === "enter") {
            searchTableEvent(e);
        }
    }
    const searchInputFocusEvent = (e) => {
        console.log('Search field in focus');
        window.addEventListener('keydown',searchInputKeyDownEvent);
    }
    const searchInputBlurEvent = (e) => {
        console.log('Search field blurred');
        window.removeEventListener('keydown',searchInputKeyDownEvent);
    }

    const modalAction = () => {

    }

    const modalForType = (type,data) => {
        let tmp = type.toLowerCase();
        switch (type.toLowerCase()) {
            case 'user':
                return (
                    <GLDSFormModal
                    top={homeViewRef.current.scrollTop}
                    title={'Create User'}
                    form={
                        <AddUserForm
                            formData={data}
                            submitAction={modalAction} 
                        />
                    }
                />
                )
            case 'organization': 
                return (
                    <GLDSFormModal
                    top={homeViewRef.current.scrollTop}
                    title={'Create Organization'}
                    form={
                        <AddOrganizationForm
                            formData={data}
                            submitAction={modalAction} 
                        />
                    }
                />
                )
            case 'product': 
                return (
                    <GLDSFormModal
                    top={homeViewRef.current.scrollTop}
                    title={'Create Product'}
                    form={
                        <AddProductForm
                            formData={data}
                            submitAction={modalAction} 
                        />
                    }
                />
                )
            case 'agent': 
                return (                    
                    <GLDSFormModal
                    top={homeViewRef.current.scrollTop}
                    title={'Create Agent'}
                    form={
                        <AddAgentForm
                            formData={data}
                            submitAction={modalAction} 
                        />
                    }
                />
                )
            default:
                break;
        }
    }


    console.log(`View: ${urlParams.view}`);    
    return (
        <div ref={homeViewRef} className="home__page__view"> 
            {formModalShowing && (modal)}
            <div className="search-view">
                <div className="search-selection-div">
                    <div className="search-selector">
                        <div className="selector-div">
                            <span>Search by {capitalizeFirstLetter(urlParams.view)}</span>
                        </div>
                    </div>
                </div>
                <div className="search-input-div">
                    <input ref={searchInputRef} onFocus={searchInputFocusEvent} onBlur={searchInputBlurEvent} type="text" placeholder={`Search ${urlParams.view.toLowerCase()}`} />
                    <div ref={searchInputBtnRef} className="search-icon-div" data-icon="search" onClick={searchTableEvent}>
                        {searchFieldIcon}                             
                    </div>
                </div>
            </div>
            <div className="table-content-view">
                <div className="table-content-div">
                    <div className="homepage__table__container">
                        <div className="ag-theme-alpine" style={{height: '100%', width: '100%'}}>
                            <AgGridReact
                                onRowEditingStopped={onRowEditingStopped}
                                onRowEditingStarted={onRowEditingStarted}
                                onRowValueChanged={onRowValueChanged}
                                onCellClicked={onCellClicked}
                                onRowClicked={rowClicked}
                                stopEditingWhenCellsLoseFocus={true}
                                editType="fullRow"
                                animateRows={true}
                                suppressClickEdit={true}
                                columnDefs={colDefs}
                                defaultColDef={defaultColDef}                                
                                rowSelection={'single'}
                                onGridReady={onGridReady}
                                rowData={rowData}
                                style={{overflow:'none'}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeView;
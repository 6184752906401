import { useState,useRef } from 'react';

const usePortal = () => {
    const [coords,setCoords] = useState({});
    const [portalState,setPortalState] = useState(false);

    const anchorRef = useRef(null);

    const updateAnchor = (_ele=null) => {        
        if (!_ele && !anchorRef.current) return false;
        
        if (_ele && anchorRef.current === null){
            anchorRef.current = _ele;
            return _ele;
        }
        if (!_ele) return anchorRef.current;
        if (anchorRef.current.isEqualNode(_ele)){
            return anchorRef.current;
        }
        anchorRef.current = _ele;
        return _ele;
    }

    const updateCoordinates = (_anchorEle) => {
        let anchor = updateAnchor(_anchorEle);
        if (anchor === false)return;
        let btnRect = anchor.getBoundingClientRect();                
        let left = btnRect.left - 255;        
        let top = btnRect.top;
        setCoords({
            left,
            top
        });
    }

    const togglePortal = () => {
        let tmpPortalState = !portalState;
        setPortalState(tmpPortalState);
        return tmpPortalState;
    }

    return [coords,updateCoordinates,portalState,togglePortal];
}

export default usePortal;
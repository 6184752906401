import React from 'react';

import styles from './threeColumnView.module.css';

const Column = ({ children }) => {
    return (
        <div className={styles.column}>
            {children}
        </div>
    )
}


function ThreeColumnView({ column1,column2,column3 }) {
    return (
        <div className={styles.three_column_view}>
            <Column>{column1}</Column>
            <Column>{column2}</Column>
            <Column>{column3}</Column>
        </div>
    );
}


export default ThreeColumnView;
import React from 'react';
import WEButton from '../../../WEButton/WEButton';
import WECheckbox from '../../../WECheckbox/WECheckbox';
import WEToggle from '../../../WEToggle/WEToggle';


import styles from './newCustomerPanel.module.css';

function NewCustomerPanel({ children }) {
    return (
        <div className={styles.new_customer_panel}>
            {children}
        </div>
    );
}

const Heading = ({ children }) => {
    return (
        <div className={styles.heading}>
              {children}
        </div>        
    )
}
NewCustomerPanel.Heading = Heading;

const FormContainer = ({ children }) => {
    return (
        <div className={styles.form}>
            <div className={styles.fields}>
                {children}
            </div>
        </div>
    )
}
NewCustomerPanel.FormContainer = FormContainer;

const NameField = ({ value,action,label,...props  }) => {
    return (
        <div className={styles.text_fields}>
            <div className={'form-floating'}>
                <input 
                    type="text" 
                    value={value} 
                    className="form-control" 
                    id={props.id} 
                    placeholder={props.placeholder} 
                    onChange={action}
                    autoComplete={"off"}
                />
                <label 
                    htmlFor={props.id} 
                    aria-label={`${label} text field`}
                >
                    {label}
                </label>
            </div>
        </div>
    )
}
NewCustomerPanel.NameField = NameField;

const EmailField = ({ value,action,label,...props  }) => {
    return (
        <div className={styles.text_fields}>
            <div className={'form-floating'}>
                <input 
                    type="email" 
                    value={value} 
                    className="form-control" 
                    id={props.id} 
                    placeholder={props.placeholder} 
                    onChange={action}
                    autoComplete={"off"}
                />
                <label 
                    htmlFor={props.id} 
                    aria-label={`${label} text field`}
                >
                    {label}
                </label>
            </div>
        </div>
    )
}
NewCustomerPanel.EmailField = EmailField;

const PasswordField = ({ value,action,label,...props  }) => {
    return (
        <div className={styles.text_fields}>
            <div className={'form-floating'}>
                <input 
                    type="password" 
                    value={value} 
                    className="form-control" 
                    id={props.id} 
                    placeholder={props.placeholder} 
                    onChange={action}
                    autoComplete={"new-password"}
                />
                <label 
                    htmlFor={props.id} 
                    aria-label={`${label} text field`}
                >
                    {label}
                </label>
            </div>
        </div>
    )
}
NewCustomerPanel.PasswordField = PasswordField;

const Toggle = ({ isChecked,action,name,label }) => {
    return (
        <div className={styles.cb_fields}>
            <WEToggle name={name} checked={isChecked} onChange={action} label={label} />
            {/* <span>Is admin?</span><WECheckbox isChecked={isChecked} action={action} name={name} /> */}
        </div>
    )
}
NewCustomerPanel.Toggle = Toggle;

const SubmitButton = ({ onClick,children }) => {
    return (
        <>
            <WEButton
                btnColor={'#00a651'}
                onClick={onClick}
                style={{width:"75%"}}
            >{children}</WEButton>
        </>
    )
}
NewCustomerPanel.SubmitButton = SubmitButton;

export default NewCustomerPanel;
import { useEffect } from 'react';
import { createPortal } from 'react-dom';

const DetailModalPortal = ({ children }) => {
    const mount = document.getElementById('detail_modal_portal');
    const el = document.createElement('div');

    useEffect(() => {
        mount.appendChild(el);
        return () => mount.removeChild(el);
    },[el,mount])
    return createPortal(children,el);
}

export default DetailModalPortal;
import React, { useRef,useEffect } from 'react';
import styles from'./GLDSFormModal.module.css';

const GLDSFormModal = ({ title,form,top,closeAction }) => {
    const modalRef = useRef(null);

    const clickEvent = (e) => {
        if (e.target.classList.contains('glds__form__modal__container')) { 
            closeAction(false);    
        }
    }
    const keydownEvent = (e) => {
        if (e.code.toLowerCase() === 'escape') {
            closeAction(false);
        }
    }

    useEffect(() => {
        window.addEventListener('click',clickEvent);
        window.addEventListener('keydown',keydownEvent)
        return () => {
            window.removeEventListener('keydown',keydownEvent)
            window.removeEventListener('click',clickEvent);
        }
    },[])    

    return (
        <div className={styles['form__modal']} style={{top:`${top}px`}}> 

            <div className={styles['modal__container']}>

                    <div className={styles['title']}>

                        <h2>{title}</h2>

                    </div>

                    <div className={styles['form']}> 

                        {form}                    

                    </div>
            
            </div>            

        </div>

    );
}

export default GLDSFormModal;
import React from 'react';

import styles from './datePicker.module.css';

function DatePicker({ children,label,name,value,action,inline=false }){
    return (
        <DatePickerContainer inline={inline}>
            {inline && <div className={styles.payment_date_inline}>
                <PickerLabel>{label ? label : "Select Date:"}</PickerLabel>
                <DateInputContainer name={name} value={value} action={action} />
            </div>}
            {!inline && <PickerLabel>{label ? label : "Select Date:"}</PickerLabel>}
            {!inline && <DateInputContainer name={name} value={value} action={action} />}
            
        </DatePickerContainer>
    );
}

const DatePickerContainer = ({ inline,children }) => {
    return (
        <div className={`${inline ? styles.date_picker_inline : styles.date_picker}`}>
            {children}
        </div>
    )
}

const PickerLabel = ({ children }) => {
    return (
        <div className={styles.picker_label}>
            <div>
                {children}
            </div>
        </div>
    )
}
DatePickerContainer.PickerLabel = PickerLabel;

const DateInputContainer = ({ name,value,action }) => {
    return (
        <div className={styles.date_input_container}>
            <div>
                <input 
                    type="date" 
                    id={name} 
                    value={value} 
                    name={name} 
                    onChange={action} 
                />
            </div>
        </div>
    )
}
DatePickerContainer.DateInputContainer = DateInputContainer;

export default DatePicker;